<template>
  <div class="firstpage">
    <div class="main-contain">
      <div class="elcarousel">
        <Carousel></Carousel>
      </div>
      <!-- <div class="notice-contain">
        <Notice></Notice>
      </div> -->
      <!-- <div class="recommended-courses">
        <Recommendedcourses></Recommendedcourses>
      </div> -->
      <div class="virtualsimulation-experiment">
        <Virtualsimulationexperiment></Virtualsimulationexperiment>
      </div>
      <!-- <div class="open-room">
        <Openroom></Openroom>
      </div> -->
      <!-- <div class="openexp-eriment">
        <Openexperiment></Openexperiment>
      </div>
      <div class="excellent-video">
        <Excellentvideo></Excellentvideo>
      </div> -->

      <div class="leader-board">
        <Leaderboard></Leaderboard>
      </div>

      <div class="links">
        <!-- <Links></Links> -->
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/views/firstpage/components/carousel.vue";
import Notice from "@/views/firstpage/components/notice.vue";
import Recommendedcourses from "@/views/firstpage/components/recommendedcourses.vue";
import Virtualsimulationexperiment from "@/views/firstpage/components/virtualsimulationexperiment.vue";
import Openroom from "@/views/firstpage/components/openroom.vue";
import Openexperiment from "@/views/firstpage/components/openexperiment.vue";
import Excellentvideo from "@/views/firstpage/components/excellentvideo.vue";
import Leaderboard from "@/views/firstpage/components/leaderboard.vue";
import Links from "@/views/firstpage/components/links.vue";
export default {
  name: "FirstPage",
  components: {
    Carousel,
    Notice,
    Recommendedcourses,
    Virtualsimulationexperiment,
    Openroom,
    Openexperiment,
    Excellentvideo,
    Leaderboard,
    Links,
  },
  data() {
    return {};
  },
  created() {
    let network = localStorage.getItem("network")
    if (!network) {
      let ip = "s3cluster2.hzau.edu.cn";
      this.ping(ip)
    }
  },
  methods: {
    ping(ip) {
      var img = new Image();
      var start = new Date().getTime();
      var flag = false;
      var isCloseWifi = true;
      var hasFinish = false;
      img.onload = function () {
        if (!hasFinish) {
          flag = true;
          hasFinish = true;
          console.log('Ping ' + ip + ' success. ');
          // alert("成功a"+ip);
          localStorage.setItem("network", 1)
        }
      };
      img.onerror = function () {
        if (!hasFinish) {
          if (!isCloseWifi) {
            flag = true;
            console.log('Ping ' + ip + ' success. ');
            // alert("成功b"+ip);
            localStorage.setItem("network", 1)
          } else {
            console.log('network is not working!');
            localStorage.setItem("network", 0)
          }
          hasFinish = true;
        }
      };
      setTimeout(function () {
        isCloseWifi = false;
        console.log('network is working, start ping...');
        // alert("开始测试"+ip);
      }, 2);
      img.src = 'http://' + ip + '/' + start;
      var timer = setTimeout(function () {
        if (!flag) {
          hasFinish = true;
          flag = false;
          console.log('Ping ' + ip + ' fail. ');
          // alert("失败c"+ip);
          localStorage.setItem("network", 0)
        }
      }, 3000);
    }
  },
};
</script>
<style scoped lang="scss">
.firstpage {
  background: #ffffff;

  .main-contain {
    .elcarousel {
      margin: 0 auto;
    }

    .notice-contain {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .recommended-courses {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }

    .virtualsimulation-experiment {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .open-room {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .openexp-eriment {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .excellent-video {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .leader-board {
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }
  }
}
</style>
